<template>
  <div class="lunbo-point-tab">
    <el-carousel :interval="5000" ref="lokk" @change="check" height="41rem" width="1920px">
      <el-carousel-item v-for="item in banner" :key="item.id">
        <h3 v-if="item.bannerType == 1">
          <video
            id="media"
            ref="videoRef"
            :src="item.videoUrl"
            style="width: 100%"
            :fill="true"
            height="759"
            autoplay="autoplay"
            loop
            preload="auto"
            controlslist="nodownload noplaybackrate "
            disablePictureInPicture="true"
            @click="clickPage(item)"
            @ended="handleended"
            :muted="true"
            :controls="false"
          ></video>
        </h3>
        <h3 :class="item.clickEvent == 0 ? 'medium' : ''" v-else>
          <img style="width: 100%;height: auto;" @click="clickPage(item)" :src="item.pictureUrl" alt="" />
        </h3>
      </el-carousel-item>
    </el-carousel>
    <!-- <el-button @click="come">点击弹框</el-button>
    <el-dialog
      style="height: 510px;"
      v-model="dialogVisible"
      width="50%"
      @close="closeDialog"
      :lock-scroll="false"
      :show-close="false"
      center
    >
      <video
        :src="src"
        preload="auto"
        class="test"
        :fill="true"
        autoplay="autoplay"
        :muted="false"
        :controls="true"
      ></video>
    </el-dialog> -->
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { machineBanner } from "@/api/Home/index.js";
import { useRouter } from "vue-router";
const router = useRouter();
const src = ref(require("../../../assets/videotest.mp4"));
const autoPlay = ref(false);
const lokk = ref(null);
const dialogVisible = ref(false);
const handleended = () => {
  autoPlay.value = true;
  lokk.value.next();
};
const machineCode = ref(1);
onMounted(() => {
  machineBanner(machineCode.value).then((res) => {
    const data = res.data;
    const dataPc = [];
    data.forEach((item) => {
      if (item.systemType == 0) {
        dataPc.push(item);
      }
    });
    banner.value = dataPc;
  });
});
const check = (val) => {
  let Media = document.getElementById("media");
  if (Media) {
    if (val == 0) {
      autoPlay.value = false;
      Media.currentTime = 0;
      Media.play();
    }
  }
};
const clickPage = (val) => {
  if (val.clickEvent == 0) {
    if (val.link) {
      if (val.openWay == 1) {
        window.location.href = val.link;
      } else {
        window.open(val.link);
      }
    }
  }
};
const come = () => {
  dialogVisible.value = true;
};
const banner = ref();
// const banner = ref([
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: true, id: 1 },
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: false, id: 2 },
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: false, id: 3 },
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: false, id: 4 },
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: false, id: 5 },
//   { img: require("@/assets/images/home/home-1071.png"), IsVideo: false, id: 6 },
// ]);
</script>
<style scoped lang="less">
::v-deep .el-dialog__header {
  display: none !important;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}
.test {
  height: 100%;
  width: 100%;
}
.medium:hover {
  cursor: pointer;
}
video {
  // object-fit: fill;
  background-color: #000;
}
.lunbo-point-tab {
  width: 1920px;
  margin: 0 auto;
	::v-deep .el-carousel__indicators{
		left: 49%;
	}
}
/* .lunbo-point-tab {
  text-align: center;
  width: 1920px;
  ::v-deep .el-carousel__indicator--horizontal.is-active::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: @xtxColor;
    transform: scaleX(0);
    animation: progress 5s linear 0s infinite;
    transform-origin: left;
    margin-top: -2px;
  }
}
@keyframes progress {
  0% {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
} */
</style>
